<script>
import emailTemplateForm from "@/views/menu/configurations/emails/templates/emailTemplateForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    emailService: "emailService",
    toastService: "toastService"
  },
})
export default class EmailTemplatetNew extends mixins(emailTemplateForm) {
  formId = "new-email-template-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }

  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.emailService.createTemplate(this.model);
      this.$router.push("/configurations/emails/list/templates");
      this.toastService.success(this.translations.success.emailTemplate_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.emailTemplates_new;
  }

  afterCreate() {
    this.model = {
      code: null,
      idLanguage: null,
      contents: null
    };
    this.isReady = true;
  }
}
</script>
